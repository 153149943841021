import React from "react";

const MoveBackIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.828 7.45885H16.9583C17.5336 7.45885 18 7.92522 18 8.50052C18 9.07582 17.5336 9.54219 16.9583 9.54219H3.828L8.51385 14.4233C8.89277 14.818 8.89277 15.4414 8.51385 15.8361C8.1124 16.2543 7.4436 16.2543 7.04216 15.8361L0.803465 9.33746C0.354517 8.86981 0.354518 8.13123 0.803466 7.66358L7.04216 1.16494C7.4436 0.746768 8.1124 0.746769 8.51385 1.16494C8.89277 1.55966 8.89277 2.18305 8.51385 2.57777L3.828 7.45885Z"
        fill="#000000"
      />
    </svg>
  );
};

export default MoveBackIcon;
