const APP_ROUTES = {
  // SIGN_IN_ROUTE: "/",
  SIGN_IN_ROUTE: "/signin",
  SIGN_UP_ROUTE: "/signup",
  FORGOT_PASSWORD_ROUTE: "/forgotPassword",
  RESET_PASSWORD_ROUTE: "/resetPassword",
  VERIFY_OTP_ROUTE: "/verifyOtp",
  HOME_ROUTE: "/home",
  DASHBOARD_ROUTE: "/dashboard",
  PROFILE_ROUTE: "/profile",
  ON_RIDE_ROUTE: "/onRide",
  OPEN_AUCTION_ROUTE: "/openAuction",
  SETTING_ROUTE: "/settings",
  WALLET_ROUTE: "/wallet",
  APP_DEFAULT_ROUTE: "/",
};
export { APP_ROUTES };
