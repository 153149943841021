import React from "react";

const BikeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Camada 1"
      viewBox="0 0 100 100"
      //   id="motorcycle"
    >
      <path d="M64.26 93.998V64.144a8.625 8.625 0 0 0-8.624-8.625 8.625 8.625 0 0 0-8.625 8.625v29.854H5.636v6h87v-6Z"></path>
      <path d="m38.3 29.723-2 46.335 5.995.257 1.254-29.064a37.334 37.334 0 0 1 24.167-.144l1.26 29.208 5.995-.257-2-46.335-5.994.259.465 10.773a43.266 43.266 0 0 0-23.617.12l.47-10.893Z"></path>
      <path d="m68.004.002-3.77 10.15a12.618 12.618 0 0 0-17.19 0L43.264.002l-26.35 3.29.74 5.95 21.64-2.7 3.88 10.44.04-.01a12.447 12.447 0 0 0-.24 2.46 12.665 12.665 0 1 0 25.33 0 12.446 12.446 0 0 0-.25-2.46l.04.01 3.88-10.44 21.64 2.7.75-5.95Zm-12.37 27.24a7.805 7.805 0 1 1 7.81-7.81 7.806 7.806 0 0 1-7.81 7.81ZM21.178 37.519h10.25v6h-10.25zM79.844 37.519h10.25v6h-10.25z"></path>
    </svg>
  );
};

export default BikeIcon;
