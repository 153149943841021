import React from "react";

const WalletIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 2"
      viewBox="0 0 35 35"
      id="wallet"
    >
      <path d="M30.59,17.72a1.25,1.25,0,0,1-1.25-1.25v-3.6A2.7,2.7,0,0,0,26.86,10H5.23a1.25,1.25,0,0,1,0-2.5H26.86a5.19,5.19,0,0,1,5,5.36v3.6A1.25,1.25,0,0,1,30.59,17.72Z"></path>
      <path d="M26.86,33.68H5.23a5.19,5.19,0,0,1-5-5.36V5.76a1.25,1.25,0,0,1,2.5,0V28.32a2.69,2.69,0,0,0,2.48,2.86H26.86a2.69,2.69,0,0,0,2.48-2.86V25.78a1.25,1.25,0,0,1,2.5,0v2.54A5.19,5.19,0,0,1,26.86,33.68Z"></path>
      <path d="M26.35 10A1.25 1.25 0 0 1 25.1 8.77V6a2.25 2.25 0 0 0-2.32-2.16h-18a1.93 1.93 0 0 0-2 1.85 1.93 1.93 0 0 0 2 1.85h11a1.25 1.25 0 0 1 0 2.5H4.75A4.43 4.43 0 0 1 .25 5.67a4.43 4.43 0 0 1 4.5-4.35h18A4.75 4.75 0 0 1 27.6 6V8.77A1.25 1.25 0 0 1 26.35 10zM31.4 27H24.47a5.11 5.11 0 0 1-5.21-5V20.19a5.11 5.11 0 0 1 5.21-5h7a3.33 3.33 0 0 1 3.32 3.34l0 5.19h0A3.34 3.34 0 0 1 31.4 27zM24.47 17.7a2.61 2.61 0 0 0-2.71 2.49v1.86a2.61 2.61 0 0 0 2.71 2.49H31.4a.83.83 0 0 0 .83-.82l0-5.19A.78.78 0 0 0 32 18a.81.81 0 0 0-.58-.25zm9 6h0z"></path>
      <circle cx="25.16" cy="21.12" r="1.25"></circle>
    </svg>
  );
};

export default WalletIcon;
