import React from "react";

const AuctionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      fill="none"
      viewBox="0 0 96 96"
      id="gavel"
    >
      <path
        stroke="#000"
        stroke-width="5"
        d="M44.6985 47.1836L52.4767 39.4054L85.1276 72.0564C87.2755 74.2043 87.2755 77.6867 85.1276 79.8345V79.8345C82.9797 81.9824 79.4973 81.9824 77.3494 79.8345L44.6985 47.1836Z"
      ></path>
      <rect
        width="20"
        height="32"
        x="37.627"
        y="54.255"
        stroke="#000"
        stroke-width="5"
        transform="rotate(-135 37.627 54.255)"
      ></rect>
      <rect
        width="28"
        height="8"
        x="34.799"
        y="62.74"
        stroke="#000"
        stroke-width="5"
        rx="4"
        transform="rotate(-135 34.799 62.74)"
      ></rect>
      <path
        stroke="#000"
        stroke-width="5"
        d="M50 81.1991C50 82.6718 48.8061 83.8657 47.3333 83.8657L12.6667 83.8657C11.1939 83.8657 10 82.6718 10 81.1991V81.1991C10 78.2535 12.3878 75.8657 15.3333 75.8657L44.6667 75.8657C47.6122 75.8657 50 78.2535 50 81.1991V81.1991Z"
      ></path>
      <rect
        width="28"
        height="8"
        x="63.083"
        y="34.456"
        stroke="#000"
        stroke-width="5"
        rx="4"
        transform="rotate(-135 63.083 34.456)"
      ></rect>
    </svg>
  );
};

export default AuctionIcon;
